import React, { Fragment, useContext, useEffect, useMemo } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';
import startPageQuery from './StartPageQuery.gql';
import Newsletter from '../Newsletter/Newsletter';
import { theme } from '../Theme';
import { useQuery } from 'react-apollo';
import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import { contentEditorComponents } from '../ContentEditor/ContentEditorComponents';
import { StoreContext } from '../StoreHandler/StoreContext';
import useFetch from 'react-fetch-hook';
import { useLocation } from 'react-router-dom';
const spacer = {
  children: [],
  type: 'Spacer',
  properties: [
    {
      name: 'height',
      type: 'dropdown',
      valueType: 'SCALAR',
      value: {
        value: 'XSMALL',
      },
    },
  ],
};

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  ${theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

function appendSpacer(a) {
  let res = a.map((e, i) => [a[i], spacer]).flat();
  return res;
}

const StartPage = ({ startPageId }) => {
  const { pathname } = useLocation();
  const result = useQuery(startPageQuery, {
    variables: { startPageId: startPageId },
  });
  const { selectedStore, storeList, updateStore } = useContext(StoreContext);
  const { loading } = result;
  const { data } = useFetch(
    `https://inviiv.io/api/content/start/?storeid=${selectedStore?.id}&datelimit=1`,
    {
      depends: [selectedStore?.id],
    }
  );
  useEffect(() => {
    const slugStore = storeList?.find(
      (store) => store?.store_slug == pathname.replace('/', '')
    );

    if (!selectedStore && slugStore?.id) {
      updateStore(slugStore);
    }
  }, [pathname, storeList?.length]);

  const moddedResult = useMemo(() => {
    if (data && result && result?.data) {
      let temp_data = { ...result };
      let found = false;
      temp_data?.data?.startPage?.data?.items.forEach((item, index) => {
        if (item.type === 'BoxRow') {
          found = index;
        }
      });

      const newItems =
        data.length > 0 && data?.[0]?.data
          ? appendSpacer([...data?.[0]?.data?.items])
          : [];

      let items = [...temp_data?.data?.startPage?.data?.items];
      items?.splice(found, 0, ...newItems);
      temp_data.data = {
        ...temp_data.data,
        startPage: {
          ...temp_data.data.startPage,
          data: {
            ...temp_data.data.startPage.data,
            items: items,
          },
        },
      };
      return temp_data;
    }
    return result;
  }, [data, selectedStore, result?.data]);
  if (loading || !result?.data) return <LoadingPage />;

  return (
    <Fragment>
      <StartPageWrapper>
        <StartPageRenderer
          result={moddedResult}
          startPageComponents={contentEditorComponents}
          LoadingPage={LoadingPage}
        />
      </StartPageWrapper>
      <Newsletter />
    </Fragment>
  );
};

export default StartPage;
