import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import Text, { SubHeading } from '@jetshop/ui/Text';
import Container from '../Layout/Container';
import NewsletterField from './NewsletterField';

import { theme } from '../Theme';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 72px 40px;
  text-align: center;
  background: #c9d3ce;
`;

const NewsletterHeading = styled(SubHeading)`
  margin: 0 auto;
  margin-bottom: 32px;
  font-size: 25px;
  font-weight: ${theme.fontWeights.regular};
`;
const NewsletterText = styled(Text)`
  margin: 0 auto;
  margin-bottom: 32px;
  font-size: 18px !important;
  font-weight: 300;
  line-height: 29px;

  ${theme.above.sm} {
    max-width: 490px;
  }

  ${theme.above.md} {
    font-size: ${theme.fontSizes[3]};
  }
`;

const Newsletter = () => {
  return (
    <StyledContainer>
      <NewsletterHeading>{t('Stay updated!')}</NewsletterHeading>
      <NewsletterText>
        {t(
          'Signup for our newsletter to discover the latest news and offers from Möbelmästarnas stores.'
        )}
      </NewsletterText>
      <NewsletterField />
    </StyledContainer>
  );
};

export default Newsletter;
