import React from 'react';
import t, { Intl } from '@jetshop/intl';
import { styled } from 'linaria/react';
import SubscribeToNewsletter from '@jetshop/core/components/Mutation/SubscribeToNewsletter';
import getErrorDetail from '@jetshop/core/helpers/getErrorDetail';
import { Button } from '../ui/Button';

import { theme } from '../Theme';

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  max-width: 590px;
  margin: 0 auto;
  margin-bottom: 2rem;
  height: auto;
  background: inherit;

  form {
    height: 100%;
    width: 100%;
    max-width: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  input,
  select {
    width: auto;
    flex: 1 1 auto;
    height: 40px;
    margin: 0;
    padding: 0 1rem;
    border: 0;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    font-size: ${theme.fontSizes[0]};
    font-family: ${theme.fonts.primary};

    &:focus {
      background: white;
    }
  }
`;

const SubmitButton = styled(Button)`
  height: 40px;
  flex: 0 1 180px;
  margin: 0 0 0 8px;
  font-family: ${theme.fonts.primary};
`;

const InputWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  max-width: 320px;
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: #650e1b;
`;

// Prevent the normal form submission behaviour, then call the
// onSubmit provided by the mutation
const submitEmail = (email, onSubmit) => (e) => {
  e.preventDefault();
  onSubmit(email);
};

class NewsletterField extends React.Component {
  state = {
    email: '',
    completed: false,
    error: false,
  };

  // State change methods
  updateEmail = (e) =>
    this.setState({ email: e.currentTarget.value, error: false });
  setCompleted = () => this.setState({ completed: true });
  setError = () => this.setState({ error: true });
  unsetError = () => this.setState({ error: false });

  render() {
    return (
      <SubscribeToNewsletter
        onCompleted={this.setCompleted}
        onError={this.setError}
      >
        {(subscribe, result) => {
          return (
            <React.Fragment>
              <Wrapper>
                {/* Show the completion success message once signed up, otherwise show the form */}
                {this.state.completed ? (
                  <p>{t('You are now subscribed')}</p>
                ) : (
                  <form onSubmit={submitEmail(this.state.email, subscribe)}>
                    <InputWrapper>
                      <Intl>
                        {/* This is necessary to use t for the placeholder string */}
                        {(t) => (
                          <input
                            type="email"
                            placeholder={'Mejladress'}
                            onChange={this.updateEmail}
                          />
                        )}
                      </Intl>
                    </InputWrapper>

                    <SubmitButton type="submit">
                      {/* Display 'Submitting…' while the form is posting */}
                      {result.loading ? t('Submitting…') : t('Submit')}
                    </SubmitButton>
                  </form>
                )}
              </Wrapper>
              {this.state.error ? (
                <Error>
                  {getErrorDetail(result.error).codes.includes(
                    'AlreadySubscribed'
                  )
                    ? t('You have already subscribed to the newsletter!')
                    : t(
                        'Something went wrong. Please check your email and try again.'
                      )}
                </Error>
              ) : null}
            </React.Fragment>
          );
        }}
      </SubscribeToNewsletter>
    );
  }
}

export default NewsletterField;
